
export default {
  name: 'BasePagination',
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    forcePage: {
      type: Number,
      default: undefined,
    },
    clickHandler: {
      type: Function,
      default: () => {},
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
    breakViewText: {
      type: String,
      default: '…',
    },
  },
  data() {
    return {
      innerValue: 1,
    }
  },
  computed: {
    prevHref() {
      if (this.firstPageSelected()) {
        return null
      }
      return `${this.$route.path}?page=${+this.selected - 1}`
    },
    nextHref() {
      if (this.lastPageSelected()) {
        return null
      }
      return `${this.$route.path}?page=${+this.selected + 1}`
    },
    selected: {
      get() {
        return this.value || this.innerValue
      },
      set(newValue) {
        this.innerValue = newValue
      },
    },
    pages() {
      const items = {}
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          const page = {
            index,
            content: index + 1,
            selected: index === +this.selected - 1,
          }
          items[index] = page
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2)

        const setPageItem = (index) => {
          const page = {
            index,
            content: index + 1,
            selected: index === +this.selected - 1,
          }

          items[index] = page
        }

        const setBreakView = (index) => {
          const breakView = {
            disabled: true,
            breakView: true,
          }

          items[index] = breakView
        }

        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i)
        }

        let selectedRangeLow = 0
        if (+this.selected - halfPageRange > 0) {
          selectedRangeLow = +this.selected - 1 - halfPageRange
        }

        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1
        }

        for (
          let i = selectedRangeLow;
          i <= selectedRangeHigh && i <= this.pageCount - 1;
          i++
        ) {
          setPageItem(i)
        }

        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1)
        }

        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1)
        }

        for (
          let i = this.pageCount - 1;
          i >= this.pageCount - this.marginPages;
          i--
        ) {
          setPageItem(i)
        }
      }
      return items
    },
  },
  beforeUpdate() {
    if (this.forcePage === undefined) return
    if (this.forcePage !== +this.selected) {
      this.selected = this.forcePage
    }
  },
  methods: {
    handlePageSelected(selected) {
      if (+this.selected === +selected) return

      this.innerValue = +selected
      this.$emit('input', +selected)
      this.clickHandler(+selected)
    },
    prevPage() {
      if (+this.selected <= 1) return

      this.handlePageSelected(+this.selected - 1)
    },
    nextPage() {
      if (+this.selected >= this.pageCount) return

      this.handlePageSelected(+this.selected + 1)
    },
    firstPageSelected() {
      return +this.selected === 1
    },
    lastPageSelected() {
      return +this.selected === +this.pageCount || +this.pageCount === 0
    },
  },
}
