
export default {
  name: 'BaseRecommendationsStreamOne',
  props: {
    stream: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    descriptions: {
      type: String,
      default: 'Эксперты, которые пишут на эту тему',
    },
    experts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    userName(user) {
      if (user?.type === 'COMPANY') {
        return user?.company?.title ? user?.company?.title : user?.username
      } else {
        return user?.profile?.firstName && user?.profile?.lastName
          ? `${user?.profile.firstName} ${user?.profile.lastName}`
          : user?.username
      }
    },
  },
}
