import { render, staticRenderFns } from "./index.vue?vue&type=template&id=60d46be6&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MegasredaContentProductFeedCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Product/FeedCard/FeedCard.vue').default,MegasredaContentEventsFeedCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Events/FeedCard/FeedCard.vue').default,MegasredaContentGoodsFeedCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Goods/FeedCard/FeedCard.vue').default,MegasredaContentVideoFeedCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Video/FeedCard/FeedCard.vue').default,MegasredaContentPostFeedCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Post/FeedCard/FeedCard.vue').default,CoreIcon: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Core/Icon/Icon.vue').default,BaseRecommendationsStreamOne: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Recommendations/Stream/One.vue').default,BasePagination: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Pagination/Pagination.vue').default})
