
import ogPagesMixins from '@/mixins/ogPagesMixins'

const POST_LIMIT = 18

// Настройки SEO полей head
const SEO = {
  TITLE: 'Новое - лента | Мегасреда',
  DESCRIPTION: 'Весь контент платформы | Мегасреда',
  H1: 'Все материалы платформы',
}

export default {
  mixins: [ogPagesMixins],
  scrollToTop: true,
  data() {
    return {
      unitedFeed: [],
      posts: [],
      promoPost: null,
      postsCount: 0,
      feedIndex: 0,
      page: this.$route.query?.page ? this.$route.query.page : 1,
    }
  },
  async fetch() {
    await this.fetchPosts()

    if (this.posts?.length) {
      const feed = []
      this.posts.forEach((post) => {
        feed.push(post)
      })
      this.unitedFeed = feed
    }
    this.feedIndex = 0

    this.promoPost = this.unitedFeed.shift()
  },
  computed: {
    seoTitle() {
      return SEO.TITLE
    },
    seoDescription() {
      return SEO.DESCRIPTION
    },
    seoH1() {
      return SEO.H1
    },
    postsOffset() {
      return (this.page - 1) * POST_LIMIT
    },
    pageCount() {
      return Math.ceil(this.postsCount / POST_LIMIT)
    },
  },
  watch: {
    $route() {
      if (!this.$route.query?.page) {
        this.page = 1
        this.posts = []
        this.$fetch()
      }
      if (this.page === 1) {
        this.$router.push({
          query: {},
        })
      }
    },
  },
  methods: {
    paginateClick() {
      this.posts = []
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      })
      this.$fetch()
      this.$scrollTo('body')
    },

    async fetchPosts() {
      const { articles, countAllPublishedArticles } = await this.$axios.$get(
        '/articles',
        {
          params: {
            limit: POST_LIMIT,
            offset: this.postsOffset,
          },
        }
      )

      if (this.posts?.length) {
        this.posts = this.posts.concat(articles)
      } else {
        this.posts = articles
      }
      this.postsCount = countAllPublishedArticles
    },

    isVideo(article) {
      return article.type === 'video'
    },

    loadMore() {
      this.page++
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      })
      this.$fetch()
    },

    updatePost() {
      this.posts = []
      this.$fetch()
    },

    removePost() {
      this.posts = []
      this.$fetch()
    },
  },
}
