/* Необходимо наличие полей в data или computed
  seoTitle: заголовок страницы
  seoDescription: описание страницы

  Необязательные (есть значение по умолчанию)
  robotsMeta: по умолчанию если есть page то noindex, follow, ничего
  additionalMeta: дополнительные meta
  seoType: тип контента (по умолчанию website)
  seoImage = {
    url: адрес картинки (https://megasreda.ru/img/megasreda_cover.jpg по умолчанию)
    urlVk: адрес картинки для vk (https://megasreda.ru/img/megasreda_cover.jpg по умолчанию)
    urlTw: адрес картинки для twitter (https://megasreda.ru/img/megasreda_cover.jpg по умолчанию)
    type: расширение (jpeg по умолчанию)
    width: ширина картинки (1200 по умолчанию)
    height: высота картинки (630 по умолчанию)
  }
*/
export default {
  data() {
    return {
      page: this.$route.query?.page ? this.$route.query.page : 1,
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: 'ru',
        prefix:
          'og: https://ogp.me/ns# article: http://ogp.me/ns/article# profile: http://ogp.me/ns/profile# fb: http://ogp.me/ns/fb#',
      },
      title: this.seoTitle,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.seoCanonical || `https://megasreda.ru${this.$route.path}`,
        },
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.seoDescription,
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'ru_RU',
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: this.seoType || 'website',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.seoUrl || `https://megasreda.ru${this.$route.path}`,
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'Мегасреда - для людей в деле',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.seoTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.seoDescription,
        },
        {
          hid: 'vk:image',
          property: 'vk:image',
          content:
            this.seoImage?.urlVk ||
            'https://megasreda.ru/img/megasreda_cover.jpg',
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content:
            this.seoImage?.urlTw ||
            'https://megasreda.ru/img/megasreda_cover.jpg',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            this.seoImage?.url ||
            'https://megasreda.ru/img/megasreda_cover.jpg',
        },
        {
          hid: 'og:image:type',
          property: 'og:image:type',
          content: this.seoImage?.type || 'image/jpeg',
        },
        {
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content: this.seoDescription,
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: this.seoImage?.width || 1200,
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: this.seoImage?.height || 630,
        },
        {
          hid: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: this.seoTitle,
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: this.seoDescription,
        },
        ...this.additionalMeta,
        ...this.robotsMeta,
      ],
    }
  },
  computed: {
    robotsMeta() {
      return this.page === 1
        ? []
        : [
            {
              hid: 'robots',
              name: 'robots',
              content: 'noindex, follow',
            },
          ]
    },
    additionalMeta() {
      return []
    },
  },
}
